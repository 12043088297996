import React, { useState } from 'react';
import {
  TableRow,
  TableCell,
  Switch,
  Checkbox,
  Button,
  Link,
  FormControlLabel,
  Typography,
  Tooltip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import CustomQuestionDrawer from './CustomQuestionDrawer/CustomQuestionDrawer';
import { wmPalette } from '../../../Theme/WorldSportsGroupTheme';

import TicketSelect from './TicketSelect';
import KebabMenu from '../../Common/KebabMenu/KebabMenu';
import ConfirmationModal from '../../Common/ConfirmationModal/ConfirmationModal';
import { mapCustomQuestion } from '../../../Utils/customQuestionsUtils';
import * as inputTypes from '../../../Consts/inputType';

const useStyles = makeStyles((theme) => ({
  coloredDisabledButton: {
    '& .Mui-disabled': {
      color: theme.palette.primary.main,
      pointerEvents: 'cursor',
      opacity: '0.5',
    },
    '& .Mui-disabled + .MuiSwitch-track': {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.5,
    },
  },
  rowInactive: {
    backgroundColor: wmPalette.grey[20],
  },
  rowActive: {
    backgroundColor: wmPalette.white[100],
  },
  padText: {
    paddingTop: theme.spacing(1),
  },
  noWidth: { minWidth: 0 },
  borderBottomNone: {
    borderBottom: 'none',
  },
}));

const formItems = [
  {
    label: 'Sports club',
    presetQuestionName: 'club',
    type: inputTypes.text,
  },
  {
    label: 'ID or Passport number',
    presetQuestionName: 'idNumber',
    type: inputTypes.text,
  },
  {
    label: 'Target time',
    presetQuestionName: 'targets',
    type: inputTypes.text,
  },
  {
    label: 'T-shirt size',
    presetQuestionName: 'size',
    extraOptionsLabel: 'Please select available T-shirt sizes',
    values: ['XXS', 'XS', 'S', 'M', 'L', 'XL', '2XL', '3XL'],
    type: inputTypes.dropdown,
  },
  {
    label: 'Phone',
    presetQuestionName: 'phone',
    type: inputTypes.text,
  },
  {
    label: 'Emergency contact',
    presetQuestionName: 'ice',
    extraOptionsLabel: 'Request ICE relationship?',
    values: ['Yes'],
    type: inputTypes.none,
  },
  {
    label: 'Address',
    presetQuestionName: 'address',
    type: inputTypes.text,
  },
];

const ConfigurableQuestions = ({
  customQuestions,
  addCustomQuestion,
  updateCustomQuestion,
  deleteCustomQuestion,
  updateRequired,
  updateAppliedTickets,
  availableTickets,
}) => {
  const [customQuestionDrawer, setCustomQuestionDrawer] = useState({
    open: false,
    question: {},
  });

  const openCustomQuestionDrawer = (question) => {
    setCustomQuestionDrawer({
      open: true,
      question: question,
    });
  };

  const [ticketModalQuestion, setTicketModalQuestion] = useState({
    open: false,
    id: '',
    label: '',
    ticketsApplied: [],
  });

  const openTicketModal = (question) => {
    setTicketModalQuestion({
      open: true,
      id: question.id,
      label: question.label,
      ticketsApplied: question.appliedTickets ?? [],
    });
  };

  const questionDrawerCallback = async (question) => {
    const q = question.id
      ? await updateCustomQuestion(mapCustomQuestion(question))
      : await addCustomQuestion(mapCustomQuestion(question));
    if (q) setCustomQuestionDrawer({ open: false, question: {} });
  };

  const toggleQuestion = async (question) => {
    let idx = customQuestions.findIndex((q) => q.id === question.id);
    if (idx === -1) {
      await addCustomQuestion(question);
    } else {
      await deleteCustomQuestion(question);
    }
  };

  const setRequired = async (id) => {
    let question = customQuestions.find((q) => q.id === id);
    question.required = !question?.required ?? true;
    await updateRequired(question);
  };

  const setAppliedTickets = (id, tickets) => {
    let question = customQuestions.find((q) => q.id === id);
    question.appliedTickets =
      tickets.length === availableTickets.length ? [] : tickets;
    updateAppliedTickets(question).then(() =>
      setTicketModalQuestion({ open: false })
    );
  };

  return (
    <>
      {formItems.map((item) => {
        let selected = customQuestions.find(
          (q) => q.presetQuestionName === item.presetQuestionName
        );
        return (
          <QuestionRow
            key={item.presetQuestionName}
            question={selected ?? item}
            selected={Boolean(selected)}
            toggleQuestion={toggleQuestion}
            openTicketModal={() => openTicketModal(selected)}
            setRequired={() => setRequired(selected.id)}
            ticketsLength={availableTickets.length}
            presetOptions={item.values}
            presetOptionsLabel={item.extraOptionsLabel}
            updateOptions={updateCustomQuestion}
          />
        );
      })}
      {customQuestions
        .filter(
          (q) => !q.presetQuestionName && q.presetOptionsLabel !== 'Gender'
        )
        .map((question, i) => (
          <QuestionRow
            key={`${question.label}-${i}`}
            question={question}
            selected={true}
            toggleQuestion={toggleQuestion}
            openTicketModal={() => openTicketModal(question)}
            openCustomQuestionDrawer={() =>
              openCustomQuestionDrawer(question, true)
            }
            setRequired={() => setRequired(question.id)}
            ticketsLength={availableTickets.length}
          />
        ))}
      <TableRow>
        <TableCell colSpan={2} style={{ borderBottom: 'none' }}>
          <Button
            component={Link}
            onClick={() => openCustomQuestionDrawer({}, false)}
          >
            + Add custom question
          </Button>
        </TableCell>
      </TableRow>
      {customQuestionDrawer.open && (
        <CustomQuestionDrawer
          questionDrawerCallback={questionDrawerCallback}
          customQuestionDrawerData={customQuestionDrawer}
          handleClose={() =>
            setCustomQuestionDrawer({ open: false, question: {} })
          }
        />
      )}

      {ticketModalQuestion.open && (
        <TicketSelect
          question={ticketModalQuestion}
          handleClose={() => setTicketModalQuestion({ open: false })}
          availableTickets={availableTickets}
          handleSubmit={setAppliedTickets}
        />
      )}
    </>
  );
};

const QuestionRow = ({
  question,
  selected,
  toggleQuestion,
  openTicketModal,
  setRequired,
  openCustomQuestionDrawer,
  updateOptions,
  presetOptionsLabel,
  presetOptions,
  ticketsLength,
}) => {
  const classes = useStyles();
  const [deleteModal, toggleDeleteModal] = useState(false);
  const toggleOption = (val, values) => {
    let valIndex = values.findIndex((x) => x === val); //Original index
    let idx = question.values.findIndex((x) => x === val);
    if (idx === -1) question.values.splice(valIndex, 0, val);
    else question.values.splice(idx, 1);

    updateOptions(question);
  };
  const isCustom = !Boolean(question.presetQuestionName);

  const deleteModalAction = () => {
    toggleQuestion(question);
    toggleDeleteModal(false);
  };

  return (
    <>
      <TableRow className={selected ? classes.rowActive : classes.rowInactive}>
        <TableCell
          style={{ width: '10%' }}
          className={
            presetOptionsLabel && selected ? classes.borderBottomNone : ''
          }
        >
          <Tooltip
            title={
              isCustom
                ? 'To remove a custom question, please delete it from the options menu on the right.'
                : ''
            }
          >
            <span>
              <Switch
                disabled={isCustom}
                checked={isCustom || selected}
                color="primary"
                onClick={() => toggleQuestion(question)}
                className={classes.coloredDisabledButton}
              />
            </span>
          </Tooltip>
        </TableCell>
        <TableCell
          align="left"
          className={
            presetOptionsLabel && selected ? classes.borderBottomNone : ''
          }
        >
          <Grid container spacing={0} direction="column">
            <Grid>
              <Typography variant="body2" className={classes.padText}>
                {question.label}
              </Typography>
            </Grid>
            {Boolean(question.subQuestions?.length) && (
              <Grid>
                <List dense>
                  {question.subQuestions.map((s, i) => (
                    <ListItem key={`${s.label}-${i}`}>
                      <ListItemIcon className={classes.noWidth}>
                        <SubdirectoryArrowRightIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography variant="caption">{s.label}</Typography>
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            )}
          </Grid>
        </TableCell>
        <TableCell
          align="center"
          className={
            presetOptionsLabel && selected ? classes.borderBottomNone : ''
          }
        >
          {Boolean(selected) && (
            <Checkbox
              onChange={setRequired}
              checked={question.required}
              disableRipple
            />
          )}
        </TableCell>
        <TableCell
          align="center"
          className={
            presetOptionsLabel && selected ? classes.borderBottomNone : ''
          }
        >
          {Boolean(selected) && (
            <Button component={Link} onClick={openTicketModal}>
              {!Boolean(question.appliedTickets?.length)
                ? 'All tickets'
                : `${question.appliedTickets.length}/${ticketsLength} Tickets`}
            </Button>
          )}
          {isCustom && (
            <KebabMenu
              optionActions={[
                { label: 'Edit', action: openCustomQuestionDrawer },
                { label: 'Delete', action: () => toggleDeleteModal(true) },
              ]}
            />
          )}
        </TableCell>
      </TableRow>
      {Boolean(!isCustom && selected && presetOptionsLabel) && (
        <TableRow>
          <TableCell>{presetOptionsLabel}</TableCell>
          <TableCell colSpan={3}>
            {presetOptions.map((o) => (
              <FormControlLabel
                key={o}
                control={
                  <Checkbox
                    value={o}
                    onChange={() => toggleOption(o, presetOptions)}
                    checked={question.values.some((x) => x === o)}
                    color="primary"
                  />
                }
                label={
                  <Typography style={{ fontSize: '0.875rem' }}>{o}</Typography>
                }
              />
            ))}
          </TableCell>
        </TableRow>
      )}
      {deleteModal && (
        <ConfirmationModal
          cancel={() => toggleDeleteModal(false)}
          confirm={deleteModalAction}
          open={deleteModal}
          title="Are you sure you want to delete the question?"
          cancelText="No, keep"
          confirmText="Yes, delete"
        />
      )}
    </>
  );
};

export default ConfigurableQuestions;
