import React, { useContext } from 'react';
import { Checkbox, FormControlLabel, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import InfoIcon from '@mui/icons-material/Info';
import { wmPalette } from '../../../../src/Theme/WorldSportsGroupTheme';
import Grid from '@mui/material/Unstable_Grid2';
import { Context } from '../../../Stores/EventInfoStore';
import { gender } from '../../../Consts/defaultQuestionnames';
const useStyles = makeStyles((theme) => ({
  defaultPaper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: wmPalette.green[5],
    boxShadow: 'none',
  },
  defaultList: {
    marginLeft: theme.spacing(3),
    marginBottom: 0,
    padding: 0,
    columns: window.innerWidth < 750 ? 1 : 3,
    minWidth: Math.min(750, window.innerWidth),
  },
}));

const defaultList = [
  { name: 'First & last name' },
  { name: 'Email address' },
  { name: 'Date of birth' },
  { name: 'Nationality' },
  {
    name: 'Gender (Male/Female)',
    options: [
      { name: 'nonBinary', type: 'checkbox', label: 'Add non-binary option' },
    ],
  },
];

const DefaultQuestionsBox = ({ updateAllowNonBinary }) => {
  const classes = useStyles();
  const [eventInfo] = useContext(Context);
  const { customQuestions } = eventInfo;

  return (
    <Paper elevation={0} className={classes.defaultPaper} p={2}>
      <Grid container spacing={0} direction="row">
        <Grid xs={1} display="flex" align="center">
          <InfoIcon color="primary" />
        </Grid>
        <Grid xs={11}>
          <Typography variant="body1" display="inline">
            These 5 questions are always included and required for all tickets.
          </Typography>
        </Grid>
        <Grid xs={1}></Grid>
        <Grid
          xs={11}
          md={7}
          display="flex"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <ul className={classes.defaultList}>
            {defaultList.map((item) => (
              <li key={item.name}>
                {item.name}
                {item.options !== undefined &&
                  item.options.map((opt) => (
                    <span key={opt.name}>
                      {opt.type === 'checkbox' && (
                        <FormControlLabel
                          label={opt.label}
                          checked={
                            customQuestions.filter((q) => q.label === gender)
                              .length > 0
                          }
                          control={
                            <Checkbox
                              onChange={(e) => {
                                updateAllowNonBinary(e.target.checked);
                              }}
                            />
                          }
                        />
                      )}
                    </span>
                  ))}
              </li>
            ))}
          </ul>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default DefaultQuestionsBox;
